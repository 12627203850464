import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AuthStore } from '../store/auth.store';

export const authGuardChild: CanActivateChildFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const authStore = inject(AuthStore);

  if (!authService.isAuthenticated()) {
    router.navigate(['/auth/login']);
    return false;
  }

  if (authStore.user()) {
    return true;
  }

  return authService.refreshToken().pipe(
    map(token => !!token),
    catchError(() => of(false)),
  );
};

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router: Router = inject(Router);

  if (!authService.isAuthenticated()) {
    return true;
  }

  router.navigate(['/home']);
  return false;
};
