import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { TokenService } from './token.service';

export const authInterceptor: HttpInterceptorFn = (httpRequest: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const excludedEndpoints = [
    '/auth/login',
    '/users/reset-password',
    new RegExp('/users/reset-password/[^/]+'),
    new RegExp('/users/create-password/[^/]+'),
  ];

  const isExcludedEndpoint = excludedEndpoints.some(endpoint =>
    typeof endpoint === 'string' ? httpRequest.url.includes(endpoint) : endpoint.test(httpRequest.url),
  );

  if (isExcludedEndpoint) {
    return next(httpRequest);
  }

  const authService = inject(AuthService);
  const tokenService = inject(TokenService);

  const newTokenFromRequestToken = new BehaviorSubject<string | null>(null);
  let isRefreshing = false;

  const { token } = tokenService.getTokenAndRefreshToken();
  const httpRequestClone = addTokenToHeader(httpRequest, token);

  return next(httpRequestClone).pipe(
    catchError((error: HttpErrorResponse) => {
      if (httpRequest.url.includes('/auth/refresh')) {
        return handleLogout();
      } else if (error.status === HttpStatusCode.Unauthorized) {
        return handle401Error(httpRequest, next, token);
      } else {
        return throwError(() => error);
      }
    }),
  );

  function addTokenToHeader(request: HttpRequest<unknown>, token: string | null): HttpRequest<unknown> {
    return token ? request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) }) : request;
  }

  function handleLogout(): Observable<never> {
    authService.logout();
    return throwError(() => new Error('Invalid token'));
  }

  function handle401Error(httpRequest: HttpRequest<unknown>, next: HttpHandlerFn, token: string | null): Observable<HttpEvent<unknown>> {
    if (!token) {
      return handleLogout();
    } else if (!isRefreshing) {
      isRefreshing = true;
      newTokenFromRequestToken.next(null);

      return authService.refreshToken().pipe(
        switchMap(({ token }) => {
          isRefreshing = false;
          newTokenFromRequestToken.next(token);
          const httpRequestClone = addTokenToHeader(httpRequest, token);
          return next(httpRequestClone);
        }),
        catchError(() => handleLogout()),
      );
    } else {
      return newTokenFromRequestToken.pipe(
        filter(token => !!token),
        take(1),
        switchMap(token => {
          const httpRequestClone = addTokenToHeader(httpRequest, token);
          return next(httpRequestClone);
        }),
      );
    }
  }
};
