import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthStore } from '../store/auth.store';
import { PermissionCode } from '../models/auth.model';

export const roleGuard: CanActivateFn = route => {
  const authStore = inject(AuthStore);
  const router = inject(Router);

  const userPermissions = authStore.user()!.permissions;
  const requiredPermissions = route.data['permissions'];

  const hasPermission = requiredPermissions.every((permission: PermissionCode) => userPermissions.includes(permission));

  if (!hasPermission) {
    router.navigate(['/home']);
    return false;
  }

  return true;
};
